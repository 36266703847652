
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchPageRT () {
    return _createElement('div', { 'className': 'cm_search-by-ymmt__search-page' }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '4950'
                }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '7690'
                }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1136'
            }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1554'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '1816'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2108'
            }, _createElement('div', {}, window.Convermax.isSearchByYMMTPage ? [_createElement('div', { 'key': '21941' }, '\n      No results were found\n      ', this.vehicleString && !this.query ? [
                    '\n        for your ',
                    _createElement('b', { 'key': '22951' }, '\'', this.vehicleString, '\'.')
                ] : null, this.query ? [
                    ' for the entered VIN number ',
                    _createElement('b', { 'key': '24421' }, '\'', this.query, '\'.')
                ] : null)] : null, !window.Convermax.isSearchByYMMTPage ? [_createElement('div', { 'key': '25781' }, '\n      No results were found for your\n      ', this.query ? [
                    ' query ',
                    _createElement('b', { 'key': '26891' }, '\'', this.query, '\'.')
                ] : null, !this.query ? [
                    ' query ',
                    _createElement('b', { 'key': '27801' }, '\'', this.search, '\'.')
                ] : null)] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '2908'
            }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3123'
            }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '3352'
            }, 'We failed to process your request', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '34980' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '35850' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '36680' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '34634' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [_createElement('aside', {
            'className': 'cm_side-panel' + (!window.document.body.classList.contains('page--category') ? ' page-sidebar category-sidebar' : ''),
            'key': '75350'
        }, _createElement('div', { 'className': 'sidebar-box' }, window.location.pathname === '/year-model-search/' ? [[this.SearchVehicleWidget_sidepanel(function () {
                    function repeatSelects1(selects, selectsIndex) {
                        return [selects(function () {
                                function repeatEntry1(entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry2(entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry3(entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry4(entry, index) {
                                    return _createElement('div', {
                                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                        'key': entry.value,
                                        'onClick': () => this.onChange(entry.term)
                                    }, this.showCheckboxes ? _createElement('input', {
                                        'type': 'checkbox',
                                        'readOnly': true,
                                        'checked': entry.selected,
                                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                        'key': '3520'
                                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                        'className': 'cm_dropdown_hitCount',
                                        'key': '3722'
                                    }, entry.hitCount) : null);
                                }
                                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                    'key': '39'
                                }, _createElement('select', {
                                    'className': 'cm_select_inner-select cm_select__pretty',
                                    'onChange': this.onChange,
                                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                    'disabled': this.disabled
                                }, this.useNativeDropdown ? [
                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                        'value': '',
                                        'key': 'null-option'
                                    }, '\n          ', '', this.title, '\n        ') : null,
                                    this.loading ? _createElement('option', {
                                        'key': 'loading-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '7541'
                                        }, 'Popular Makes'),
                                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '7544'
                                        }, 'All Makes'),
                                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                    ] : null,
                                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                ] : null, !this.useNativeDropdown ? _createElement('option', {
                                    'key': '_current',
                                    'value': '_current'
                                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                            return _createElement('div', { 'className': 'cm_filterInput' });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                        'key': '2842'
                                    },
                                    !this.hideNullOption ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'onClick': () => this.onChange('')
                                    }, '\n      ', '', this.title, '\n    ') : null,
                                    this.loading ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                ]) : null);
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__side-panel cm_SearchVehicleWidget_sidepanel' }, this.template === 'active' ? _createElement('div', {
                        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
                        'key': '103'
                    }, _createElement('h3', {}, 'Search by Year, Make, Model, and Trim'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                        _map(this.selects, repeatSelects1.bind(this))
                    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button',
                        'onClick': this.selectVehicle,
                        'disabled': !this.allowToSetVehicle
                    }, '\n                Search\n              '), _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button',
                        'onClick': this.discardVehicle,
                        'disabled': !this.allowToDiscardVehicle
                    }, '\n                Clear\n              ')))) : null, _createElement('p', { 'className': 'cm_vehicle-widget_not-listed' }, 'Vehicle not listed? ', _createElement('a', { 'href': '/contact-us/' }, 'Click Here')));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]] : null, window.location.pathname !== '/year-model-search/' ? [
            _createElement('h3', { 'key': '153811' }, 'Search by Year, Make, Model, and Trim'),
            _createElement('a', {
                'className': 'button',
                'href': '/year-model-search/',
                'key': '153813'
            }, 'YEAR/MAKE/MODEL/TRIM')
        ] : null), _createElement('div', { 'className': 'cm_side-panel__search-by-size sidebar-box' }, _createElement('h3', {}, 'Search by Size and other specifications'), _createElement('p', {}, 'Search by Size, Style, and more'), _createElement('a', {
            'className': 'button',
            'href': '/custom-search/'
        }, 'Custom Search')), _createElement('div', { 'className': 'cm_side-panel__search-by-part-number sidebar-box' }, _createElement('h3', {}, 'Search by Part Number'), _createElement('p', {}, 'Search using the OEM part number printed on your OE lift'), [this.PartNumberSearchBox(function () {
                return _createElement('div', { 'className': 'cm_search-box-root__part-number cm_search-box-root__side-panel cm_PartNumberSearchBox' }, _createElement('form', { 'onSubmit': this.preventFormSubmission }, _createElement('div', { 'className': 'cm_search-box_form-container' }, [this.searchInput(function () {
                        return _createElement('div', { 'className': 'form-input cm_searchInput' }, 'Enter Part Number');
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })], _createElement('span', { 'className': 'cm_search-box_clear-container' }, this.inputNotEmpty ? _createElement('span', {
                    'className': 'cm_search-box_clear',
                    'onClick': this.clearInput,
                    'key': '531'
                }, '\n              ', window.innerWidth > 768 ? '\u2715' : 'Clear', '\n            ') : null)), _createElement('button', {
                    'data-cm-role': 'add-query',
                    'className': 'button'
                }, 'Search')));
            }, {
                widgetName: 'undefined',
                items: undefined
            })], _createElement('p', { 'className': 'sidebar-box-info' }, _createElement('small', {}, _createElement('b', {}, 'Example:'), ' F85-278-406A-11AA\n        ', _createElement('br', {}), _createElement('b', {}, 'Enter Above:'), ' F85278406A11AA\n      '))), _createElement('div', { 'className': 'sidebar-box sidebar-box-transparent' }, _createElement('h3', {}, _createElement('i', { 'className': 'fa fa-exclamation-circle' }), ' Please Note'), '\n    Items sold on our site are sold individually unless otherwise noted. Images are to illustrate front and\n    rear of supports, please purchase the quantity needed in your order. Thank you!', _createElement('br', {}), _createElement('br', {}), _createElement('h3', {}, 'Still can\'t find your lift support?'), _createElement('a', { 'href': '/contact-us/' }, 'Click here to contact us'), ' and we\'ll find it for you and please allow 24 business\n    hours for reply.\n  '))], [_createElement('div', {
            'id': 'itemsBlock',
            'className': 'page-content cm_main-content',
            'key': '180440'
        }, [this.SearchVehicleWidget_label(function () {
                return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__label cm_SearchVehicleWidget_label' }, this.template === 'locked' ? _createElement('div', {
                    'className': 'cm_vehicle-widget_label cmTemplate_locked',
                    'key': '90'
                }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, this.vehicleString)) : null);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], _createElement('p', { 'className': 'results-info' }, '\n    Items ', _createElement('b', {}, this.pageFrom), ' to ', _createElement('b', {}, this.pageTo), ' of a total ', _createElement('b', {}, this.totalHits)), [this.SearchResult(function () {
                function repeatItems1(items, itemsIndex) {
                    return [items(function () {
                            function mergeProps(inline, external) {
                                var res = Object.assign({}, inline, external);
                                if (inline.hasOwnProperty('style')) {
                                    res.style = _.defaults(res.style, inline.style);
                                }
                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                    res.className = external.className + ' ' + inline.className;
                                }
                                return res;
                            }
                            function repeatI2(i, iIndex) {
                                return _createElement('span', {
                                    'key': i,
                                    'className': 'icon ' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'icon--ratingEmpty' : this.review_average - i < 0.75 ? 'icon--ratingHalf' : 'icon--ratingFull')
                                }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-star' })));
                            }
                            function onSubmit3(e) {
                                {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            }
                            function onClick4(e) {
                                window.Convermax.addToCart(e);
                            }
                            return _createElement('div', { 'className': (this.group_title ? 'category-info group' : 'category-product') + (this.last_group_item ? ' listItem-last-group-item' : '') }, this.group_title ? _createElement('h2', { 'key': '177' }, _createElement('div', { 'className': 'item-location' }, this.location), this.notes ? _createElement('span', {
                                'className': 'item-notes',
                                'key': '264'
                            }, this.notes) : null) : null, !this.group_title ? [
                                _createElement('div', {
                                    'className': 'title-container',
                                    'key': '3391'
                                }, _createElement('h4', { 'className': 'listItem-title' }, _createElement('a', Object.assign({}, { 'href': this.custom_url }, { dangerouslySetInnerHTML: { __html: this.name_search_by_ymmt } }))), this.sku ? _createElement('p', {
                                    'className': 'listItem-subtitle',
                                    'key': '576'
                                }, '\n        Sku: ', _createElement('span', mergeProps({ 'className': 'listItem-sku' }, { dangerouslySetInnerHTML: { __html: this.sku } }))) : null),
                                _createElement('div', {
                                    'className': 'info-containers',
                                    'key': '3393'
                                }, _createElement('div', { 'className': 'left-container' }, _createElement('div', {
                                    'className': 'card-text',
                                    'data-test-info-type': 'price'
                                }, _createElement('div', { 'className': 'productView-rating' }, [_createElement.apply(this, [
                                        'div',
                                        {
                                            'className': 'productView-rating',
                                            'key': '9300'
                                        },
                                        _map([
                                            0,
                                            1,
                                            2,
                                            3,
                                            4
                                        ], repeatI2.bind(this)),
                                        _createElement('span', { 'className': 'productView-reviewLink' }, !this.review_count ? ['(No reviews yet)'] : null, this.review_count ? [
                                            '(',
                                            this.review_count,
                                            ' reviews)'
                                        ] : null)
                                    ])])), _createElement('div', { 'className': 'price-section price-section--withoutTax' }, this.retail_price > this.price ? _createElement('span', {
                                    'className': 'price price--rrp',
                                    'key': '1624'
                                }, '\n            ', this.formatPrice(this.retail_price), '\n          ') : null, _createElement('span', { 'className': 'price price--withoutTax' }, this.formatPrice(this.price), ' ', this.Quantity_Sold_As)), _createElement('p', { 'className': 'listItem-shipping' }, this.availability_description?.includes('Express shipping') ? _createElement('img', {
                                    'className': 'shipping-icon',
                                    'src': '/product_images/uploaded_images/free-shipping.png',
                                    'alt': 'Express shipping icon',
                                    'key': '1939'
                                }) : null, this.availability_description?.includes('Usually ships within 24 hours') ? _createElement('img', {
                                    'className': 'shipping-icon',
                                    'src': 'https://portal.liftsupportsdepot.com/images/mini/24icon.png',
                                    'alt': '24 hours shipping icon',
                                    'key': '2140'
                                }) : null, this.availability_description?.includes('Will ship on or before') ? _createElement('img', {
                                    'className': 'shipping-icon',
                                    'src': 'https://portal.liftsupportsdepot.com/images/mini/calendaricon.png',
                                    'alt': 'Shipping date icon',
                                    'key': '2365'
                                }) : null, _createElement('span', { dangerouslySetInnerHTML: { __html: this.availability_description } }))), _createElement('div', { 'className': 'right-container' }, this.brand_name ? _createElement('div', {
                                    'className': 'manufacturer-container',
                                    'key': '2743'
                                }, _createElement('div', { 'className': 'manufacturer-title' }, 'Manufactured By:'), this.brand_image ? _createElement('img', {
                                    'src': this.brand_image,
                                    'key': '2879'
                                }) : null, !this.brand_image ? _createElement('div', mergeProps({
                                    'className': 'listItem-brand-name',
                                    'key': '2946'
                                }, { dangerouslySetInnerHTML: { __html: this.brand_name } })) : null) : null, _createElement('form', {
                                    'className': 'listItem-buttons',
                                    'action': '/cart.php',
                                    'onSubmit': onSubmit3.bind(this)
                                }, !this.has_options ? [
                                    _createElement('input', {
                                        'type': 'hidden',
                                        'name': 'action',
                                        'value': 'add',
                                        'key': '32231'
                                    }),
                                    _createElement('input', {
                                        'type': 'hidden',
                                        'name': 'product_id',
                                        'value': this.id,
                                        'key': '32233'
                                    }),
                                    _createElement('label', { 'key': '32235' }, 'Qty:'),
                                    _createElement('input', {
                                        'className': 'form-input form-input--incrementTotal',
                                        'id': 'qty[]',
                                        'name': 'qty[]',
                                        'type': 'tel',
                                        'defaultValue': this.Custom_Qty ?? 1,
                                        'data-quantity-min': '0',
                                        'data-quantity-max': '0',
                                        'min': '1',
                                        'pattern': '[0-9]*',
                                        'aria-live': 'polite',
                                        'key': '32237'
                                    }),
                                    _createElement('input', {
                                        'id': 'form-action-addToCart',
                                        'className': 'button button--primary add-to-cart',
                                        'type': 'submit',
                                        'value': this.availability === 'preorder' ? `Pre-Order Now` : `Add to Cart`,
                                        'onClick': onClick4.bind(this),
                                        'key': '32239'
                                    })
                                ] : null, _createElement('a', {
                                    'className': 'button',
                                    'href': this.custom_url
                                }, 'Learn More'))))
                            ] : null);
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_SearchResult' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'category-group cmRepeater_items' },
                    _map(this.items, repeatItems1.bind(this))
                ]));
            }, {
                widgetName: 'results',
                items: undefined
            })], [this.pagination(function () {
                function repeatButtons1(buttons, buttonsIndex) {
                    return [buttons(function () {
                            return this.template === 'prev' ? _createElement('div', { 'className': 'cmTemplate_prev' }) : this.template === 'next' ? _createElement('div', { 'className': 'cmTemplate_next' }) : this.template === 'page' ? _createElement('li', { 'className': 'pagination-item' + (this.isActive ? ' pagination-item--current' : '') + ' cmTemplate_page' }, this.isActive ? _createElement('a', {
                                'className': 'pagination-link',
                                'key': '119'
                            }, this.page) : null, !this.isActive ? _createElement('a', {
                                'className': 'paging-link pagination-link',
                                'key': '188'
                            }, this.page) : null) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_pagination' }, _createElement.apply(this, [
                    'ul',
                    { 'className': 'pagination nxt-address pagination-list cmRepeater_buttons' },
                    _map(this.buttons, repeatButtons1.bind(this))
                ]));
            }, {
                widgetName: 'undefined',
                items: undefined
            })])]);
}
        export const componentNames = ["cm:message","cm:filterInput","cm:SearchVehicleWidget_sidepanel","cm:searchInput","cm:PartNumberSearchBox","cm:SearchVehicleWidget_label","cm:SearchResult","cm:pagination"]