
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SidePanelRT () {
    return _createElement('aside', { 'className': 'cm_side-panel' + (!window.document.body.classList.contains('page--category') ? ' page-sidebar category-sidebar' : '') }, _createElement('div', { 'className': 'sidebar-box' }, window.location.pathname === '/year-model-search/' ? [[this.SearchVehicleWidget_sidepanel(function () {
                function repeatSelects1(selects, selectsIndex) {
                    return [selects(function () {
                            function repeatEntry1(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry2(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry3(entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry4(entry, index) {
                                return _createElement('div', {
                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                    'key': entry.value,
                                    'onClick': () => this.onChange(entry.term)
                                }, this.showCheckboxes ? _createElement('input', {
                                    'type': 'checkbox',
                                    'readOnly': true,
                                    'checked': entry.selected,
                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                    'key': '3520'
                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                    'className': 'cm_dropdown_hitCount',
                                    'key': '3722'
                                }, entry.hitCount) : null);
                            }
                            return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                'key': '39'
                            }, _createElement('select', {
                                'className': 'cm_select_inner-select cm_select__pretty',
                                'onChange': this.onChange,
                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                'disabled': this.disabled
                            }, this.useNativeDropdown ? [
                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                    'value': '',
                                    'key': 'null-option'
                                }, '\n          ', '', this.title, '\n        ') : null,
                                this.loading ? _createElement('option', {
                                    'key': 'loading-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '7541'
                                    }, 'Popular Makes'),
                                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '7544'
                                    }, 'All Makes'),
                                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                ] : null,
                                this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                            ] : null, !this.useNativeDropdown ? _createElement('option', {
                                'key': '_current',
                                'value': '_current'
                            }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                        return _createElement('div', { 'className': 'cm_filterInput' });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                    'key': '2842'
                                },
                                !this.hideNullOption ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'onClick': () => this.onChange('')
                                }, '\n      ', '', this.title, '\n    ') : null,
                                this.loading ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                            ]) : null);
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__side-panel cm_SearchVehicleWidget_sidepanel' }, this.template === 'active' ? _createElement('div', {
                    'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
                    'key': '103'
                }, _createElement('h3', {}, 'Search by Year, Make, Model, and Trim'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                    _map(this.selects, repeatSelects1.bind(this))
                ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                    'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button',
                    'onClick': this.selectVehicle,
                    'disabled': !this.allowToSetVehicle
                }, '\n                Search\n              '), _createElement('div', {
                    'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button',
                    'onClick': this.discardVehicle,
                    'disabled': !this.allowToDiscardVehicle
                }, '\n                Clear\n              ')))) : null, _createElement('p', { 'className': 'cm_vehicle-widget_not-listed' }, 'Vehicle not listed? ', _createElement('a', { 'href': '/contact-us/' }, 'Click Here')));
            }, {
                widgetName: 'undefined',
                items: undefined
            })]] : null, window.location.pathname !== '/year-model-search/' ? [
        _createElement('h3', { 'key': '78341' }, 'Search by Year, Make, Model, and Trim'),
        _createElement('a', {
            'className': 'button',
            'href': '/year-model-search/',
            'key': '78343'
        }, 'YEAR/MAKE/MODEL/TRIM')
    ] : null), _createElement('div', { 'className': 'cm_side-panel__search-by-size sidebar-box' }, _createElement('h3', {}, 'Search by Size and other specifications'), _createElement('p', {}, 'Search by Size, Style, and more'), _createElement('a', {
        'className': 'button',
        'href': '/custom-search/'
    }, 'Custom Search')), _createElement('div', { 'className': 'cm_side-panel__search-by-part-number sidebar-box' }, _createElement('h3', {}, 'Search by Part Number'), _createElement('p', {}, 'Search using the OEM part number printed on your OE lift'), [this.PartNumberSearchBox(function () {
            return _createElement('div', { 'className': 'cm_search-box-root__part-number cm_search-box-root__side-panel cm_PartNumberSearchBox' }, _createElement('form', { 'onSubmit': this.preventFormSubmission }, _createElement('div', { 'className': 'cm_search-box_form-container' }, [this.searchInput(function () {
                    return _createElement('div', { 'className': 'form-input cm_searchInput' }, 'Enter Part Number');
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('span', { 'className': 'cm_search-box_clear-container' }, this.inputNotEmpty ? _createElement('span', {
                'className': 'cm_search-box_clear',
                'onClick': this.clearInput,
                'key': '531'
            }, '\n              ', window.innerWidth > 768 ? '\u2715' : 'Clear', '\n            ') : null)), _createElement('button', {
                'data-cm-role': 'add-query',
                'className': 'button'
            }, 'Search')));
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement('p', { 'className': 'sidebar-box-info' }, _createElement('small', {}, _createElement('b', {}, 'Example:'), ' F85-278-406A-11AA\n        ', _createElement('br', {}), _createElement('b', {}, 'Enter Above:'), ' F85278406A11AA\n      '))), _createElement('div', { 'className': 'sidebar-box sidebar-box-transparent' }, _createElement('h3', {}, _createElement('i', { 'className': 'fa fa-exclamation-circle' }), ' Please Note'), '\n    Items sold on our site are sold individually unless otherwise noted. Images are to illustrate front and\n    rear of supports, please purchase the quantity needed in your order. Thank you!', _createElement('br', {}), _createElement('br', {}), _createElement('h3', {}, 'Still can\'t find your lift support?'), _createElement('a', { 'href': '/contact-us/' }, 'Click here to contact us'), ' and we\'ll find it for you and please allow 24 business\n    hours for reply.\n  '));
}
        export const componentNames = ["cm:filterInput","cm:SearchVehicleWidget_sidepanel","cm:searchInput","cm:PartNumberSearchBox"]